<template>
  <!-- 收益 -->
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="代理人:" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="table.params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区域:" prop="area">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: false}"
            @change="selArea">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
      </template>
      <template v-slot:update_time="{row}">
        <span v-if="row.status == 1">--</span>
        <span v-else>{{row.update_time}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{row.status == 1 ? '正常': '禁用'}}</span>
      </template>
    </VTable>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BannerList',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "代理人",width: '120', hidden: false },
        { name: "phone", label: "联系电话",width: '120', hidden: false },
        { name: "province", label: "代理地区", showTooltip:true, hidden: false },
        { name: "order_count", label: "交易工单量/个",width: '120', hidden: false },
        { name: "order_amount", label: "工单金额/元", width: '120', hidden: false },
        { name: "amount", label: "代理收益/元", width: '120', hidden: false },
        { name: "worker_amount", label: "师傅收益/元", width: '120', hidden: false },
        // { name: "platform_amount", label: "平台收益/元", width: '120', hidden: false },
        { name: "create_time", label: "代理时间", width: '160', hidden: false },
        { name: "status", label: "状态", width: '120', hidden: false },
        { name: "update_time", label: "结束时间", width: '160', hidden: false },
      ],
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          status: '',
          province: '',
          city: '',
          area: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_agency/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset() {
      this.table.params = {
        name: '',
        phone: '',
        status: '',
        province: '',
        city: '',
        area: '',
        page: 1,
        count: 10
      }
      this.getTable();
    },
    selArea(val) {
      this.table.params.province = val[0];
      this.table.params.city = val[1];
      this.table.params.area = val[2];
    },
    // 获取省、市、区
     getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
  }
}
</script>